import React from "react"

import Layout from "../components/Layout"

import SliderSection from "../components/SliderSection"

import ServicesSection from "../components/ServicesSection"

import WhoAreWeSection from "../components/WhoAreWeSection"

import WhereAreWeSection from "../components/WhereAreWeSection"

import ContactSection from "../components/ContactSection"

import Slide from "../components/Slide"

import slideImage0 from "./../../slike/slide/slide0.jpg";
import slideImage1 from "./../../slike/slide/slide1.jpg";
import slideImage2 from "./../../slike/slide/slide2.jpg";

export default function Home() {
  return (
	<Layout title="GEO KONTURA d.o.o.">
		<SliderSection>
			<Slide backgroundImage={slideImage0} >
				<h3 className="kn-slide-title">Ovlaštena tvrtka za obavljanje geodetske djelatnosti.</h3>
			</Slide>
			<Slide backgroundImage={slideImage1} >
				<h3 className="kn-slide-title">Ovlaštena tvrtka za obavljanje geodetske djelatnosti.</h3>
			</Slide>
			<Slide backgroundImage={slideImage2} >
				<h3 className="kn-slide-title">Ovlaštena tvrtka za obavljanje geodetske djelatnosti.</h3>
			</Slide>
		</SliderSection>
		<ServicesSection id="usluge" />
		<WhoAreWeSection id="o-nama" />
		<WhereAreWeSection />
		<ContactSection id="kontakt" />
	</Layout>
)
}
