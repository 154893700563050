import React from "react"

import { useState, useRef, useMemo} from "react"

import useOnScreen from "../hooks/useOnScreen"

import AnimatedHeading from "../components/AnimatedHeading"

import Service from "./Service"

import dataUsluge from "../content/usluge.json"

import "../styles/servicesSection.css"

export default function ServicesSection( { id}) {
	
	const [ activeService, setActiveService] = useState( -1)
	
	const ref = useRef()
	
	const sectionVisible = useOnScreen(ref)
	
	const subChildren = useMemo( () => <h2 className="kn-services-title">Naše usluge</h2>,[])
	
	return (
		<section ref={ref} id={ id} className={ `kn-services-section${ sectionVisible ? " kn-section-visible" : ""}`}>
		
			<div className="kn-services-title-container">
				<span className="kn-services-title-divider-left"></span>
				<AnimatedHeading className="kn-who-are-we-title" delay={400} delayBetween={20}>
					{ subChildren}
				</AnimatedHeading>
				<span className="kn-services-title-divider-right"></span>
			</div>
			
			<div role="button" tabIndex={ -1} className="kn-services-container" onMouseLeave={ () => setActiveService(-1)}>
				{ dataUsluge.map( ( key, index) => <Service icon={ ( <img className={ "kn-service-image"} src={ `/static${ key.pngIcon}`} alt={key.title}/>)} link={key.slug} text={key.introText} title={key.title} activeService={ activeService} setActiveService={ setActiveService} index={ index} />)}
			</div>	
			
		</section>
	)
}