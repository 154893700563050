import React from "react"

//import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../styles/service.css"

const Service = ( { index, icon, link, text, title, activeService, setActiveService}) => {
	
	
	return (
		<div cover bg="linear-gradient(60deg, #29323c 0%, #485563 100%)" to={ link} role="button" tabIndex={ index + 1} className={`kn-service${ activeService === index ? " kn-active-service" : ""}`} onFocus={ () => setActiveService( index)} onMouseOver={ () => setActiveService( index)}>
			<div className="kn-service-icon">
				{ icon}
			</div>
			<h3 className="kn-service-title">
				{ title}
			</h3>
			<span className="kn-service-divider"/>
			<p className="kn-service-description">
				{ text}
			</p>		
		</div>
	)
}

export default Service

/*<Link className="kn-service-button" to={ href}>
	Pročitaj više
</Link>*/