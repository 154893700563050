import React from "react"

import { useRef } from "react"

import AnimatedHeading from "../components/AnimatedHeading"

import useOnScreen from "../hooks/useOnScreen"

import "../styles/whoAreWeSection.css"

export default function WhoAreWeSection({ id }) {

	const ref = useRef()
	const sectionVisible = useOnScreen(ref)

	return (
		<section id={id} ref={ref} className={`kn-who-are-we-section${sectionVisible ? " kn-section-visible" : ""}`}>

			<div className="kn-who-are-we-outer-section">
				<div className="kn-who-are-we-content">

					<AnimatedHeading className="kn-who-are-we-title" delay={600} delayBetween={20}>
						<h2 className="kn-who-are-we-title">O nama</h2>
					</AnimatedHeading>

					<div className="kn-who-are-we-divider"></div>

					<p className="kn-who-are-we-introtext">GEO KONTURA d.o.o. je tvrtka čija je osnovna djelatnost pružanje geodetskih usluga.
						<br />Posjedujemo sve potrebne suglasnosti i ovlaštenja za obavljanje stručnih geodetskih poslova.
						<br />Ured nam se nalazi u Biogradu na Moru, Bukovačka 23, (Poduzetnički inkubator, 2. kat).
						<br />Koristimo modernu i inovativnu geodetsku i informatičku opremu sa licenciranim programima za obradu i analizu podataka.</p>
				</div>
				<div className="kn-who-are-we-image-container">
				</div>
			</div>

		</section>
	)
}